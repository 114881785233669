// app.js

import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Markdown from '@ckeditor/ckeditor5-markdown-gfm/src/markdown';
// import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import CodeBlock from '@ckeditor/ckeditor5-codeblock-with-syntax-highlight/src/codeblock'
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';


class ClassicEditor {
  setup(element, config) {
    return ClassicEditorBase.create(element, Object.assign({
      plugins: [
        Essentials,
        Paragraph,
        Bold,
        Italic,
        Underline,
        Code,
        CodeBlock,
        Link,
        List,
        BlockQuote,
        Heading,
        TextTransformation,
        Autoformat,
        HorizontalLine
      ],
    }, config))
  }
}
window.addEventListener('DOMContentLoaded', () => {
    window.ClassicEditor = new ClassicEditor()
})
